



















/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import Community from '@/models/graphql/Community';
import ChangePasswordForm from '@/views/guest/resetPassword/ChangePasswordForm.vue';
import MessageButtonPage from '@/views/guest/MessageButtonPage.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import MessagePage from '@/views/guest/MessagePage.vue';

const accountStore = namespace('AccountStore');

type checkType = {
  validToken: boolean;
  expiredToken: boolean;
  activated: boolean;
  activatable: boolean;
}

@Component({
  components: { MessagePage, MessageButtonPage, ChangePasswordForm },
})
export default class ResetPasswordPage extends Vue {
  @Getter
  community!: Community;

  @Prop({ required: false, default: '' })
  token!: string;

  @accountStore.Action
  private checkActivatability!: (
    payload: {
      token: string;
      email: string | null;
      password: string | null;
    }
  ) => Promise<checkType | null>;

  private fetchAccount: {
    validToken: boolean;
    expiredToken: boolean;
    activated: boolean;
    activatable: boolean;
  } | null = null;

  private pageType = '';

  private IllustrationType = IllustrationType;

  mounted(): void {
    if (this.token && this.token !== '') {
      const parsedToken = this.parseJwt(this.token);
      let userUid = '';
      if (parsedToken) {
        userUid = parsedToken.u ? parsedToken.u : '';
      }
      if (userUid === '') {
        this.$router.push({ name: 'find-account-email' });
      }

      this.checkActivatability({
        token: this.token,
        email: null,
        password: null,
      }).then((responseData) => {
        this.fetchAccount = responseData;
      }).catch(() => {
        this.pageType = 'expired-link';
      });
    } else {
      this.pageType = 'expired-link';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  parseJwt(token: string): {c: string; e: string; exp: number; iat: number; u: string} {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+')
      .replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('')
      .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
  }

  @Watch('fetchAccount')
  private accountChange(): void {
    if (this.fetchAccount) {
      if (this.fetchAccount.validToken) {
        this.pageType = 'new-password';
        return;
      }

      if (!this.fetchAccount.validToken) {
        if (this.fetchAccount.expiredToken) {
          this.pageType = 'password-expired';
          return;
        }
        this.$router.push({ name: 'find-account-email' });
        return;
      }
    }

    this.$router.push({ name: 'find-account-email' });
  }

  private onResetPasswordSuccess(): void {
    this.pageType = 'password-success';
  }
}
