var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container change-password"},[_c('div',{staticClass:"row justify-content-center h-100 mt-5 mt-sm-5 mt-md-6 mt-lg-6 mt-xl-6"},[_c('div',{staticClass:"col-12 col-lg-6 col-xl-8"},[_c('h1',{staticClass:"mb-2 normal"},[_vm._v(" "+_vm._s(_vm.$t('app.global.change-password-form.title'))+" ")]),_c('p',{staticClass:"mb-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('app.global.change-password-form.message'))+" ")]),_c('p',{staticClass:"mb-0 text-neutral-n-6-label"},[_c('strong',[_vm._v(_vm._s(_vm.tokenUserEmail))])]),_c('b-form',{staticClass:"form mt-xl-5 mt-lg-5 mt-md-5 mt-sm-4 mt-4",on:{"submit":_vm.onSubmit}},[_c('div',[_c('input-text',{attrs:{"default-value":_vm.$v.form.password.$model,"has-errors":!_vm.validateState('password'),"label":_vm.$t('app.global.change-password-form.placeholder.new-password'),"required":true,"type":"password"},on:{"update:defaultValue":function($event){return _vm.$set(_vm.$v.form.password, "$model", $event)},"update:default-value":function($event){return _vm.$set(_vm.$v.form.password, "$model", $event)},"blur":function($event){return _vm.onBlur('password')}}}),_c('input-text',{staticClass:"mt-3",attrs:{"default-value":_vm.$v.form.repeatPassword.$model,"has-errors":_vm.$v.form.password.$model !== _vm.$v.form.repeatPassword.$model,"label":_vm.$t('app.global.change-password-form.placeholder.confirm-new-password'),"required":true,"type":"password"},on:{"update:defaultValue":function($event){return _vm.$set(_vm.$v.form.repeatPassword, "$model", $event)},"update:default-value":function($event){return _vm.$set(_vm.$v.form.repeatPassword, "$model", $event)},"blur":function($event){return _vm.onBlur('repeatPassword')}}})],1),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"d-flex",class:_vm.isMobile
              ? 'flex-column'
              : 'justify-content-between'},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex justify-content-center align-center mr-2"},[_c('div',{staticClass:"error-dot",class:{
                      'invalid': _vm.$v.form.password.$dirty
                        && _vm.$v.form.password.$model.length < 8,
                      'valid': _vm.$v.form.password.$dirty && _vm.$v.form.password.$model.length >= 8
                    }})]),_c('p',{staticClass:"mb-0 medium text-left",class:{
                    'text-neutral-n-5-placeholder': !_vm.$v.form.password.$dirty,
                    'text-red-r-3-primary-red': _vm.$v.form.password.$dirty
                      && _vm.$v.form.password.$model.length < 8
                  }},[_vm._v(" "+_vm._s(_vm.$t('app.global.change-password-form.validation.errors.characters-number'))+" ")])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex justify-content-center align-center mr-2"},[_c('div',{staticClass:"error-dot",class:{
                      'invalid': _vm.$v.form.password.$dirty && !_vm.$v.form.password.valid && !_vm.hasNumber,
                      'valid': _vm.$v.form.password.$dirty && _vm.hasNumber
                    }})]),_c('p',{staticClass:"mb-0 medium text-left",class:{
                    'text-neutral-n-5-placeholder': !_vm.$v.form.password.$dirty,
                    'text-red-r-3-primary-red': _vm.$v.form.password.$dirty
                      && !_vm.$v.form.password.valid && !_vm.hasNumber
                  }},[_vm._v(" "+_vm._s(_vm.$t('app.global.change-password-form.validation.errors.number'))+" ")])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex justify-content-center align-center mr-2"},[_c('div',{staticClass:"error-dot",class:{
                      'invalid': _vm.$v.form.password.$dirty
                        && _vm.$v.form.$invalid
                        && _vm.$v.form.password.$model
                          !== _vm.$v.form.repeatPassword.$model,
                      'valid': _vm.$v.form.password.$dirty
                        && _vm.$v.form.password.$model === _vm.$v.form.repeatPassword.$model
                    }})]),_c('p',{staticClass:"mb-0 medium text-left",class:{
                    'text-neutral-n-5-placeholder': !_vm.$v.form.password.$dirty,
                    'text-red-r-3-primary-red': _vm.$v.form.password.$dirty
                      && _vm.$v.form.$invalid
                      && _vm.$v.form.password.$model
                        !== _vm.$v.form.repeatPassword.$model
                  }},[_vm._v(" "+_vm._s(_vm.$t('app.global.change-password-form.validation.errors.password-not-match'))+" ")])])]),_c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex justify-content-center align-center mr-2"},[_c('div',{staticClass:"error-dot",class:{
                      'invalid': _vm.$v.form.password.$dirty
                        && !_vm.$v.form.password.valid
                        && !_vm.isUppercase,
                      'valid': _vm.$v.form.password.$dirty && _vm.isUppercase
                    }})]),_c('p',{staticClass:"mb-0 medium text-left",class:{
                    'text-neutral-n-5-placeholder': !_vm.$v.form.password.$dirty,
                    'text-red-r-3-primary-red': _vm.$v.form.password.$dirty
                      && !_vm.$v.form.password.valid
                      && !_vm.isUppercase
                  }},[_vm._v(" "+_vm._s(_vm.$t('app.global.change-password-form.validation.errors.uppercase'))+" ")])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex justify-content-center align-center mr-2"},[_c('div',{staticClass:"error-dot",class:{
                      'invalid': _vm.$v.form.password.$dirty
                        && !_vm.$v.form.password.valid
                        && !_vm.isLowercase,
                      'valid': _vm.$v.form.password.$dirty && _vm.isLowercase
                    }})]),_c('p',{staticClass:"mb-0 medium text-left",class:{
                    'text-neutral-n-5-placeholder': !_vm.$v.form.password.$dirty,
                    'text-red-r-3-primary-red': _vm.$v.form.password.$dirty
                      && !_vm.$v.form.password.valid
                      && !_vm.isLowercase
                  }},[_vm._v(" "+_vm._s(_vm.$t('app.global.change-password-form.validation.errors.lowercase'))+" ")])])])])]),_c('div',{staticClass:"mt-xl-5 mt-lg-5 mt-md-5 mt-sm-4 mt-4"},[_c('button-component',{attrs:{"disabled":_vm.disabledSubmit(),"text":_vm.$t('app.global.change-password-form.button.continue'),"size":"lg","type":"submit","variant":"primary"},on:{"on-click":_vm.onSubmit}})],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }